import React, { useState, ReactNode } from 'react';
import { CheckContext } from '.';

interface CheckProviderProps {
  children: ReactNode;
}

export const CheckProvider: React.FC<CheckProviderProps> = ({ children }) => {
  // This state is used to check which checkID is clicked when user clicks down
  // arrow (to show tree elements) from the 'Top 10 failed checks`
  const [openCheckId, setOpenCheckId] = useState<string | null>(null);
  // Flag to retain the 'Top 10 failed checks' filter on the check list when navigating to a new cluster.
  // This filter should only be shown when user visits from 'Top 10 failed checks'.
  const [isFavoritesOrFailedChecksActive, setIsFavoritesOrFailedChecksActive] =
    useState<boolean>(false);

  return (
    <CheckContext.Provider
      value={{
        openCheckId,
        setOpenCheckId,
        isFavoritesOrFailedChecksActive,
        setIsFavoritesOrFailedChecksActive,
      }}
    >
      {children}
    </CheckContext.Provider>
  );
};
