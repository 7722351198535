import React from 'react';
import styles from './styles.module.scss';

interface ActionHeaderProps {
  actionButtonType?: string | null;
  isFromCurrentCluster?: boolean; // Prop to toggle styles
  hasFailStatus?: boolean;
}

const ActionHeader: React.FC<ActionHeaderProps> = ({
  actionButtonType,
  isFromCurrentCluster,
  hasFailStatus,
}) => {
  const shouldRenderAction =
    isFromCurrentCluster && actionButtonType && hasFailStatus;

  return (
    <div
      className={`${styles.dataHeader} ${isFromCurrentCluster ? styles.paddingStyles : styles.marginStyles}`}
    >
      <div className={styles.headerItemCheck}>
        {isFromCurrentCluster ? 'Object/Check' : 'Check'}
      </div>
      <div
        style={{ paddingLeft: !hasFailStatus ? '2rem' : '0.6rem' }}
        className={styles.headerItemResultStatus}
      >
        {isFromCurrentCluster ? 'Result' : 'Status'}
      </div>
      {shouldRenderAction && (
        <div className={styles.headerItemAction}>Action</div>
      )}
    </div>
  );
};

export default ActionHeader;
