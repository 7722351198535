import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { SummaryCluster } from 'pages/dashboard/shared/models';
import { Search } from 'components/search';
import { FilterMeta, FilterTags } from 'components/filter-tags';
import { patchCluster } from 'pages/dashboard/shared/requests';
import { ApiError, ClusterUpdateRequest } from 'shared/api/client';
import { useToast } from 'shared/providers/toast/context';
import { useCheckSummary } from 'pages/dashboard/context';
import { getDate } from 'components/utils';
import { getTopFailedChecks } from 'pages/dashboard/shared/requests';
import { ClusterTopFailedCheck } from 'shared/api/client';
import ChecksList from 'components/checks-list';
import ClusterList from 'components/cluster-list';
import { ClusterItem, IgnoredCluster } from 'types/clusterInterfaces';
import { useCheckContext } from './context';

interface CheckSummaryClustersProps {
  clusters: Record<string, SummaryCluster>;
  filters: FilterMeta;
  onClear?: (key: keyof SummaryCluster, value: string) => void;
}

export const CheckSummaryClusters = ({
  clusters,
  filters,
  onClear,
}: CheckSummaryClustersProps) => {
  const navigate = useNavigate();
  const [isTopFailedChecksClicked, setIsTopFailedChecksClicked] =
    useState(false);
  const [isFavoritesClicked, setIsFavoritesClicked] = useState(false);
  const { date } = useCheckSummary();
  const { showApiError } = useToast();
  // 'setIsFavoritesOrFailedChecksActive' is used to detect whether user has came from 'Top failed checks' tab
  // or from 'Favorites' tab and hence as per this flag we filter the check out when user clicks the cluster from the tree
  // when 'isFavoritesOrFailedChecksActive' is false, no filter should be applied and render all the checks
  const { setIsFavoritesOrFailedChecksActive } = useCheckContext();
  const [topFailedChecks, setTopFailedChecks] = useState<
    ClusterTopFailedCheck[]
  >([]);
  const [favoriteChecks, setFavoriteChecks] = useState<ClusterTopFailedCheck[]>(
    [],
  );
  const TOP_FAILED_CHECK_LIMIT =
    parseInt(window.__RUNTIME_CONFIG__.TOP_FAILED_CHECK_LIMIT, 10) || 10;

  const onClickCluster =
    (id: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      navigate(`/cluster/${id}`);
    };

  const [nonIgnoredClusters, setNonIgnoredClusters] = useState<ClusterItem[]>(
    [],
  );
  const [ignoredClusters, setIgnoredClusters] = useState<ClusterItem[]>([]);
  const [loadingClusterIgnore, setLoadingClusterIgnore] = useState<
    string | null
  >(null);
  const [loadingClusterMoveUp, setLoadingClusterMoveUp] = useState<
    string | null
  >(null);
  const [isLoadingTopFailedChecks, setIsLoadingTopFailedChecks] =
    useState(false);

  // Initialize clusters in state once the component is mounted
  useEffect(() => {
    const newIgnoredClusters: ClusterItem[] = [];
    const clustersDataList: ClusterItem[] = [];

    Object.entries(clusters || {}).forEach(([k, v]) => {
      const clusterItem: ClusterItem = {
        label: v.name,
        value: v.clusterId,
        failedCount: v.failedCount,
        errorCount: v.noResultCount,
        _origin: v,
      };

      // Check if the cluster should be ignored
      if (v.ignore) {
        newIgnoredClusters.push(clusterItem); // Collect ignored clusters
      } else {
        clustersDataList.push(clusterItem); // Add to main clusters
      }
    });

    setNonIgnoredClusters(clustersDataList); // No null values here
    setIgnoredClusters(newIgnoredClusters); // Update ignored clusters all at once
    // Also on render this flag will be false as we are on 'clusters' tab first
    setIsFavoritesOrFailedChecksActive(false);
  }, [clusters, setIsFavoritesOrFailedChecksActive]);

  const filteredClustersDataList = nonIgnoredClusters.filter((item) => {
    return Object.entries(filters).every(([field, filter]) => {
      const itemValue = ((item || {})._origin || {})[
        field as keyof SummaryCluster
      ];
      const filterValue = filter?.value;

      if (Array.isArray(filterValue)) {
        if (Array.isArray(itemValue)) {
          return filterValue
            .map(String)
            .some((val) => itemValue.map(String).includes(val));
        } else {
          return filterValue.map(String).includes(String(itemValue));
        }
      } else if (filterValue) {
        if (Array.isArray(itemValue)) {
          return itemValue.map(String).includes(String(filterValue));
        }
        return itemValue === String(filterValue);
      }
      return true;
    });
    // return Object.entries(filters).every(([filed, filter]) => {
    //   return filter?.value
    //     ? String(
    //         ((item || {})._origin || {})[filed as keyof SummaryCluster],
    //       ) === String(filter.value)
    //     : true;
    // });
  });

  const getClusterTreeNodes = (checkId: string) => {
    const failedCheck = topFailedChecks.find(
      (check) => check.check_id === checkId,
    );
    const clustersForCheck = failedCheck ? failedCheck.clusters : [];

    return clustersForCheck
      .map((clusterDetails) => ({
        key: clusterDetails.id || '',
        label: clusterDetails.name,
      }))
      .filter((node) => node.key); // Filter out nodes with empty keys
  };

  const fetchTopFailedObjects = useCallback(async () => {
    const executionDate = getDate((date || new Date())?.toJSON());
    setIsLoadingTopFailedChecks(true); // Set loading state to true
    try {
      const failedObjectsResponse = await getTopFailedChecks(
        executionDate,
        TOP_FAILED_CHECK_LIMIT,
      );
      const response = await failedObjectsResponse();
      setTopFailedChecks(response.failed_objects);
      const favoriteChecksList = response.failed_objects.filter(
        (item) => item.favorite === true,
      );
      setFavoriteChecks(favoriteChecksList);
    } catch (error) {
      if (error instanceof ApiError) {
        showApiError(error);
      }
    } finally {
      setIsLoadingTopFailedChecks(false); // Set loading state to false
    }
  }, [date, showApiError, TOP_FAILED_CHECK_LIMIT]);

  // Effect to handle priority filter changes
  useEffect(() => {
    if (filters.priority?.value) {
      // Set to Clusters
      setIsTopFailedChecksClicked(false);
      setIsFavoritesClicked(false);
    }
  }, [filters.priority?.value]);

  useEffect(() => {
    fetchTopFailedObjects();
  }, [date, fetchTopFailedObjects]);

  const handleIgnoreClick = async (cluster: ClusterItem) => {
    setLoadingClusterIgnore(cluster.label);
    // Check if the cluster is already ignored
    const isAlreadyIgnored = ignoredClusters.some(
      (c) => c.label === cluster.label,
    );
    if (!isAlreadyIgnored) {
      const requestBody: ClusterUpdateRequest = {
        ignore: true,
      };
      try {
        await patchCluster({
          clusterId: cluster.value,
          requestBody,
        });
        // If successful, update the state
        setNonIgnoredClusters(
          nonIgnoredClusters.filter((c) => c.label !== cluster.label),
        ); // Remove from main list
        setIgnoredClusters([...ignoredClusters, cluster]);
      } catch (error) {
        if (error instanceof ApiError) {
          showApiError(error);
        }
      } finally {
        setLoadingClusterIgnore(null);
      }
    } else {
      setLoadingClusterIgnore(null);
    }
  };

  const handleMoveUpClick = async (cluster: IgnoredCluster) => {
    // Find the original cluster from `clusters` by label
    const originalCluster = Object.values(clusters).find(
      (c) => c.name === cluster.label,
    );
    if (!originalCluster) return; // Handle the case where the cluster isn't found

    setLoadingClusterMoveUp(cluster.label);

    const requestBody: ClusterUpdateRequest = {
      ignore: false,
    };

    try {
      await patchCluster({
        clusterId: cluster.value,
        requestBody,
      });

      // Remove the cluster from ignoredClusters
      setIgnoredClusters(
        ignoredClusters.filter((c) => c.label !== cluster.label),
      );
      // Add the cluster back to nonIgnoredClusters with full data
      setNonIgnoredClusters((prevMainClusters) => [
        ...prevMainClusters,
        {
          label: cluster.label,
          value: originalCluster?.clusterId, // Retrieve the value from the original cluster
          failedCount: cluster.failedCount,
          errorCount: cluster.errorCount,
          _origin: originalCluster, // Reattach the original data
        },
      ]);
    } catch (error) {
      if (error instanceof ApiError) {
        showApiError(error);
      }
    } finally {
      setLoadingClusterMoveUp(null);
    }
  };

  const SHOW_FAVORITES = window.__RUNTIME_CONFIG__.SHOW_FAVORITES;
  return (
    <div className={cx(styles.dataListView)}>
      <div className={cx(styles.header)}>
        <h4
          className={cx(styles.title, {
            [styles.active]: !isTopFailedChecksClicked && !isFavoritesClicked,
          })}
          style={{ width: '5.375rem' }}
          onClick={() => {
            setIsTopFailedChecksClicked(false);
            setIsFavoritesClicked(false);
            // Set to false when not on 'Top 10 failed checks' or 'Favorites' tab
            setIsFavoritesOrFailedChecksActive(false);
          }}
        >
          Clusters
        </h4>
        {/* <div className={cx(styles.sortBy)}>
          <label className={cx(styles.label)}>Sort By:</label>
          <Dropdown
            value={null}
            //onChange={(e) => setSelectedCity(e.value)}
            options={[{ label: '' }]}
            optionLabel="name"
            placeholder="Select..."
            className={cx(styles.sortByFilter)}
          />
        </div> */}
        <h4
          className={cx(styles.title, {
            [styles.active]: isTopFailedChecksClicked,
          })}
          style={{ width: '9.625rem' }}
          onClick={() => {
            setIsTopFailedChecksClicked(true);
            setIsFavoritesClicked(false);
            setIsFavoritesOrFailedChecksActive(true);
          }}
        >
          Top {TOP_FAILED_CHECK_LIMIT} Failed{' '}
          {TOP_FAILED_CHECK_LIMIT === 1 ? 'Check' : 'Checks'}
        </h4>
        {SHOW_FAVORITES === 'true' && (
          <h4
            className={cx(styles.title, {
              [styles.active]: isFavoritesClicked,
            })}
            style={{ width: '5.375rem' }}
            onClick={() => {
              setIsFavoritesClicked(true);
              setIsTopFailedChecksClicked(false);
              setIsFavoritesOrFailedChecksActive(true);
            }}
          >
            Favorites
          </h4>
        )}
      </div>

      {!isTopFailedChecksClicked && !isFavoritesClicked ? (
        <>
          {Object.keys(filters || {})?.length > 0 && (
            <div className={cx(styles.headerFilter)}>
              <FilterTags
                titles={{
                  isHealthy: 'is Healthy',
                  priority: 'Priority',
                  severity: 'Severity',
                  reportStatus: 'Report Status',
                }}
                onClear={onClear}
                filters={filters || {}}
              />
            </div>
          )}
          <Search
            value=""
            className={styles.withSearch}
            data={filteredClustersDataList}
            searchFields={['label']}
            resultsComponent={(res, value) => (
              <ClusterList
                filteredClustersDataList={res}
                ignoredClusters={ignoredClusters}
                handleMoveUpClick={handleMoveUpClick}
                loadingClusterMoveUp={loadingClusterMoveUp}
                onClickCluster={onClickCluster}
                handleIgnoreClick={handleIgnoreClick}
                loadingClusterIgnore={loadingClusterIgnore}
              />
            )}
          />
        </>
      ) : isFavoritesClicked ? (
        <Search
          value=""
          className={styles.withSearch}
          data={favoriteChecks}
          searchFields={['check_name']}
          resultsComponent={(res, value) => (
            <ChecksList
              checksList={res}
              getClusterTreeNodes={getClusterTreeNodes}
              isLoading={isLoadingTopFailedChecks}
              isFavoritesClicked={isFavoritesClicked}
            />
          )}
        />
      ) : (
        <Search
          value=""
          className={styles.withSearch}
          data={topFailedChecks}
          searchFields={['check_name']}
          resultsComponent={(res, value) => (
            <ChecksList
              checksList={res}
              getClusterTreeNodes={getClusterTreeNodes} // Passed function to get tree nodes
              isLoading={isLoadingTopFailedChecks}
              isTopFailedChecksClicked={isTopFailedChecksClicked}
            />
          )}
        />
      )}
    </div>
  );
};
