import React, { useEffect } from 'react';
import { Icon, LoadingDots } from 'components';
import styles from './styles.module.scss';
import cx from 'classnames';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tree } from 'primereact/tree';
import { useNavigate } from 'react-router-dom';
import { ClusterTopFailedCheck } from 'shared/api/client';
import ActionHeader from 'components/action-header';
import { useCheckContext } from 'pages/check-summary/context';

interface ChecksListProps {
  checksList: ClusterTopFailedCheck[];
  getClusterTreeNodes: (checkId: string) => { key: string; label: string }[];
  isLoading: boolean;
  isFavoritesClicked?: boolean;
  isTopFailedChecksClicked?: boolean;
}

const ChecksList: React.FC<ChecksListProps> = ({
  checksList,
  getClusterTreeNodes, // Receiving getClusterTreeNodes as a prop
  isLoading,
  isFavoritesClicked,
  isTopFailedChecksClicked,
}) => {
  const navigate = useNavigate();
  // State to track the currently open check ID
  const { openCheckId, setOpenCheckId } = useCheckContext();

  // Effect to close the dropdown when either of the flags are false
  useEffect(() => {
    if (!isFavoritesClicked || !isTopFailedChecksClicked) {
      setOpenCheckId(null);
    }
  }, [isFavoritesClicked, isTopFailedChecksClicked, setOpenCheckId]);

  // Handler to toggle the dropdown
  const handleToggleDropdown = (checkId: string) => {
    if (openCheckId === checkId) {
      setOpenCheckId(null); // Close the currently open dropdown
    } else {
      setOpenCheckId(checkId); // Open the new dropdown
    }
  };

  return (
    <div className={cx(styles.dataListView)}>
      <div className={styles.viewListBox}>
        <div className="absolute">
          {isLoading ? ( // Show spinner if loading
            <div className={styles.spinnerContainer}>
              <ProgressSpinner />
              Please wait <LoadingDots />
            </div>
          ) : checksList.length === 0 ? (
            <div className={styles.noData}>Data is not currently available</div>
          ) : (
            <>
              <ActionHeader isFromCurrentCluster={false} />
              <div
                className={cx(styles.list)}
                style={{ height: '100%', overflowY: 'auto' }}
              >
                {checksList.map((opt, index) => (
                  <div
                    key={opt.check_id}
                    className={cx(styles.itemList, {
                      [styles.even]: index % 2 === 0,
                      [styles.link]: true,
                    })}
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 120px',
                    }}
                  >
                    <div
                      className={styles.chevronTreeContainer}
                      style={{ display: 'flex' }}
                    >
                      <div
                        onClick={() => handleToggleDropdown(opt.check_id)}
                        className={styles.checkItemContainer}
                      >
                        <Icon
                          className={styles.icon}
                          size={'1.5rem'}
                          name={
                            openCheckId === opt.check_id
                              ? 'expand_less'
                              : 'expand_more'
                          }
                          style={{ marginRight: '1rem' }}
                        />
                        <h6 className={styles.name}>{opt.check_name}</h6>
                      </div>
                    </div>
                    <div
                      className={styles.data}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div className={styles.item}>
                        <Icon
                          className={cx(styles.icon, styles.fail)}
                          size={'1.5rem'}
                          name="fail"
                        />
                        <span>{opt?.fail_count}</span>
                      </div>
                    </div>
                    {openCheckId === opt.check_id && (
                      <div
                        className={styles.treeWrapper}
                        style={{ gridColumn: '1 / -1' }}
                      >
                        <Tree
                          value={getClusterTreeNodes(opt.check_id)}
                          expandIcon={true}
                          onNodeClick={(node) => {
                            if (node.node.label) {
                              navigate(`/cluster/${node.node.key}`);
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChecksList;
