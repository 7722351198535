export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME_FORMAT = 'yyyy-MM-dd hh:mm a';
// RTS api to check the cluster configuration existence or get the cluster configuration if it already exist
export const CLUSTER_CONFIG_API_URL = `${window.origin}/v1alpha1/vpn/clients/config`;
// RTS api to create the cluster configuration
export const CLUSTER_CONFIG_CREATION_URL = `${window.origin}/v1alpha1/vpn/clients`;
// Jira redirection url
export const JIRA_URL = 'https://www.atlassian.com/software/jira';
// DevRev redirection url
export const DEVREV_URL = 'https://app.devrev.ai/';
// ACTION_BUTTON options
export const ACTION_BUTTON_OPTIONS = ['jira', 'devrev'];
// DevRev icon variants
export const DEVREV_ICON_VARIANTS = [
  'variant-e927',
  'variant-e928',
  'variant-e929',
  'variant-e92a',
];
