import React from 'react';
import styles from './styles.module.scss';
import cx from 'classnames';
import {
  ACTION_BUTTON_OPTIONS,
  DEVREV_ICON_VARIANTS,
  DEVREV_URL,
  JIRA_URL,
} from 'shared/constants';
import { Icon } from 'components/icon';
import { useToast } from 'shared/providers/toast/context';
import { ReportModel, ReportTableDataItem } from 'pages/reports/shared/models';
import { Ripple } from 'primereact/ripple';

interface ActionButtonProps {
  actionButtonType: string | null; // Define allowed types
  reportResponse: ReportModel | null;
  iconStyle?: React.CSSProperties;
  name: string; // Check Name
  title: string; // Failed Object Name
}

const ActionButton: React.FC<ActionButtonProps> = ({
  actionButtonType,
  iconStyle,
  name,
  title,
  reportResponse,
}) => {
  const { showToast } = useToast();
  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (reportResponse?.items) {
      const foundItem = Object.values(
        reportResponse.items as { [key: string]: ReportTableDataItem },
      ).find((item) => item.check === title && item.object === name);

      if (foundItem) {
        const filteredItem: ReportTableDataItem = {};

        // These conditional checks are done if any one of the filtereditems is missing, it should skip
        // that and proceed copying the next one to copy the failed object to clipboard
        if (foundItem.object) filteredItem.object = foundItem.object;
        if (foundItem.resourceType)
          filteredItem.resourceType = foundItem.resourceType;
        if (foundItem.namespace) filteredItem.namespace = foundItem.namespace;
        if (foundItem.explanation)
          filteredItem.explanation = foundItem.explanation;

        handleCopy(filteredItem, event);
      }
    }
  };

  const handleCopy = (
    data: ReportTableDataItem,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const dataToCopy = JSON.stringify(data, null, 2);
    navigator.clipboard
      .writeText(dataToCopy)
      .then(() => {
        showToast({
          severity: 'success',
          summary: 'Success',
          detail: 'Data copied to clipboard successfully!',
          life: 3000,
        });
        setTimeout(() => {
          event.stopPropagation();
          const url =
            actionButtonType === ACTION_BUTTON_OPTIONS[0]
              ? JIRA_URL
              : DEVREV_URL;
          window.open(url, '_blank');
        }, 1500);
      })
      .catch(() => {
        showToast({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to copy data to clipboard!',
          life: 3000,
        });
      });
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.actionButtonContainer}>
        <button className={styles.actionButton} onClick={handleClick}>
          <Icon
            className={cx(
              actionButtonType === ACTION_BUTTON_OPTIONS[0] && styles.icon,
              actionButtonType === ACTION_BUTTON_OPTIONS[1] &&
                styles.icon_devrev,
            )}
            size={'1.5rem'}
            name={
              actionButtonType === ACTION_BUTTON_OPTIONS[0] ? 'Jira' : 'DevRev'
            }
          />
          {actionButtonType === ACTION_BUTTON_OPTIONS[1] && (
            <div className={styles.devRevIconContainer}>
              {DEVREV_ICON_VARIANTS.map((variant) => (
                <Icon
                  key={variant}
                  size="1.1rem"
                  name="DevRev_multicolor"
                  className={`${variant} ${styles.devRevIcon}`}
                  style={iconStyle}
                />
              ))}
            </div>
          )}
          <Ripple />
        </button>
        <span className={styles.tooltipText}>
          {actionButtonType === ACTION_BUTTON_OPTIONS[0]
            ? 'Create Jira ticket'
            : 'Create DevRev ticket'}
        </span>
      </div>
    </div>
  );
};

export default ActionButton;
