import { createContext, useContext } from 'react';

interface CheckContextType {
  openCheckId: string | null;
  setOpenCheckId: (id: string | null) => void;
  isFavoritesOrFailedChecksActive: boolean;
  setIsFavoritesOrFailedChecksActive: (flag: boolean) => void;
}

export const CheckContext = createContext<CheckContextType | undefined>(
  undefined,
);

export const useCheckContext = () => {
  const context = useContext(CheckContext);
  if (!context) {
    throw new Error('useCheckContext must be used within a CheckProvider');
  }
  return context;
};
